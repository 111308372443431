/* Header Styles */
@media (min-width: 0px) {

	header {
		border: 1px solid black;
		border-radius: 6px;
		width: 100%;
		max-width: 768px;
		height: 142px;
		margin: 0;
		overflow: hidden;
		position: relative;
	}

	.company-logo {
		position: absolute;
		display: none;
	   
		img {
			height: auto;
			width: auto;
		}
	}

	.company-name {
		line-height: 1;
		position: absolute;
		text-decoration: none;
		
		&:hover {
			text-decoration: none;
		}
	}

	.company-motto {
		line-height: 1;
		position: absolute;
	}
}
/* Layout
/////////////////////// */
html {
	overflow-y: scroll;
}

body {
	font-size: 8pt;
}


h1 {
	margin-top: 0;
}

.navbar {
	border: none;

	a {
		transition: all .4s;
	}

}

.container {
	width: auto;

	&.xs-main-container {
		background: white;
		padding: 10px;
		width: 790px;
		display: table;

		> .row {
			margin: 0;
		}
	}
}

/* Specific width of main content to account for sidebar width */
.col-sm-10 {
	width: 620px;
	max-width: 620px;
	display: table-cell;
	float: none;
}

/* Call to Action Styles
/////////////////////// */
#XSCallToAction {
	margin-bottom: 10px;
}

sup {
	font-size: 0.5em;
}


/* Header Styles
/////////////////////// */
@import 'header';

/* Navbar (Primary nav) Styles
/////////////////////// */
@import 'navbar';


/* Main Content Styles
/////////////////////// */
@import 'main';


/* Sidebar Styles
/////////////////////// */
@import 'sidebar';

/* Footer Styles
/////////////////////// */
@import 'footer';

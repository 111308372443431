/* Sidebar Styles */
@media (min-width: 0px) {
	
	.xs-sidebar {
		height: auto;
		vertical-align: top;
		white-space: nowrap;
	}


	.xs-profile-picture {
		display: none;

		margin: 1em auto;
		padding: 0;
		text-align: center;

		img {
			border: 1px solid black;
			height: auto;
			margin: auto;
			max-width: 100%;
		}
	}


	#xs-sidebar {
		border-radius: 6px;
		display: table-cell;
		min-width: 160px;
		padding: 0;
		width: 160px;

		ul.nav {
			margin: 1em 5px;
		}

		li {
			border: 1px solid rgba(white, 0.75);			
			border-radius: 4px;
			display: block;
			margin: 0 0 2px 0;
			position: relative;
			width: 150px;



			&.spacer {
				background: transparent;
				height: 24px;
				border: none;
			}

			/* indented items */
			&.indent > a {
				padding-left: 16px;
			}

			/* has sub-nav child */
			&.has-children {
				/* right arrow indicator */
				svg.sub-indicator {
					opacity: 0.5;
					position: absolute;
					top: 6px;
					right: 3px;
				}
			}

			/* 'fly-out' sub-navigation */
			.sub-nav {
				background: transparent;
				display: none;
				left: 150px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: 150px;
				z-index: 101;

				li {
					a {
						padding: 6px;

						&:hover {
							text-decoration: none;
							background-color: rgba(white, 0.5);
						}
					}

					&.indent {
						a {
							padding-left: 12px;
						}
					}
				}
			}

			/* on li:hover
			SHOW .sub-nav */
			&:hover {
				.sub-nav {
					display: block;
				}
			}

			a {
				border-radius: 4px;
				display: block;
				padding: 0 6px;
				transition: all .4s;
				overflow: hidden;

				svg {
					margin: 0 6px 0 0;
				}

				&:hover {
					background-color: rgba(white, 0.5);
				}

			}
		}

		/* xs-alt-navigation links */
		.xs-alt-navigation {

			li {
				border: none;
				background-color: transparent;
				margin-bottom: 1em;

				a {
					text-indent: -999em;
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: center;
					min-height: 31px;

					/* "Tell a Friend */
                    &[href="/TellaFriend"], &[href="/tellfriend.x"] {
                        background-image: url(/images/tellafriend.png);
                    }
                    
                    /* "Adobe Acrobat */
                    &[href="/DownloadAdobeAcrobat"], &[href="/downloadadobe.x"] {
                        background-image: url(/images/acrobat.png);
                    }
				}
			}
		}/* /.xs-alt-navigation */

		/* Language options */
		.xs-language-options {
		    background: rgba(white,0.375);
		    border: 1px solid rgba(black,0.5);
		    font-size: 0.8em;
		    margin: 7px 5px 1em 5px;
		    padding: 0.5em 0;

		    label {
		        margin-bottom: 0;
		    }

		    a {
		    	color: #337ab7;
		    	font-family: initial;
		    	font-size: 9.6px;
		    	font-style: normal;
		    	font-weight: normal;
		    	line-height: 13.7143px;
		    	text-align: center;
		        text-decoration: underline;

		        &::after {
		            content: "|";
		            display: inline-block;
		            margin: 0 0.25em 0 0.5em;
		            text-decoration: none;
		        }

		        &:last-child {
					&::after {
						display: none;
					}
				}
		    }

		} /* /.xs-language-options */

	}	
}


/* Primary navigation */
@media (min-width: 0px) {
	
	/* <nav> */
	#xs-navbar-collapse {
		margin: 0;
		padding: 0;
		position: absolute;
		top: 6px;
		right: 16px;
		width: 150px;
		

		/* <ul> */
		.xs-primary-navigation {
			width: 150px;

			li {
				border: 1px solid white;
				border-radius: 4px;
				margin: 0 0 2px 0;
				text-align: left;
				width: 150px;
				height: 20px;

				a {
					border-radius: 4px;
					width: 150px;
					height: 20px;
					line-height: 20px;
					padding: 0 5px;
					overflow: hidden;
					transition: background-color .4s;

					&:hover {
						background-color: rgba(white, 0.8);
					}
				}

				&.spacer {
					width: 150px;
					height: 20px;
					background-color: transparent;
					border: none;
				}
			}
		}
	} /* /#xs-navbar-collapse */

	.navbar {
		/* Specific height of main nav */
		/*Desktop Styling*/
			min-height: 30px;
	}
	.navbar-nav>li>a {
		/*Desktop Styling*/
			padding: 3px 0;	
			/*font-size: 10px;*/
	}
}
/* Main Content Styles */
@media (min-width: 0px){
	
	main {
		padding-top: 10px;
	}

	.embed-wrapper, iframe {
	    position: relative;
		z-index: 0;
	}

	.xs-main-content {
		display: table-cell; 
	}

}